import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HTMLReactParser from "html-react-parser"
import { getValueD9, renameImage, getDescription } from "../utils"
import SocialSharing from "../components/SocialSharing"
import Documents from "../components/Documents"

const PageTemplate = ({ data, ...props }) => {
  const image = renameImage(
    getValueD9(
      data.nodeSsActu,
      "relationships.field_cover.relationships.field_media_image.uri.value",
      false
    ),
    null,
    "photo_galerie_paysage"
  )
  const body = getValueD9(data.nodeSsActu, "body.processed", "")
  return (
    <Layout title={data.nodeSsActu.title} {...props} description={getDescription(body)}>
      {image !== false && (
        <div className="uk-cover-container uk-height-medium">
          <img src={image} alt={data.nodeSsActu.title} uk-cover="" />
        </div>
      )}

      <div className="uk-section uk-padding uk-background-default">
        {HTMLReactParser(body)}
        <SocialSharing />
        <Documents data={getValueD9(data.nodeSsActu, "relationships.field_files", [])} />
      </div>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query Actu_ByID($id: String!) {
    nodeSsActu(id: { eq: $id }) {
      title
      body {
        processed
      }
      relationships {
        field_cover {
          relationships {
            field_media_image {
              url
              uri {
                url
                value
              }
            }
          }
        }
      }
    }
  }
`
//  field_files {
//         relationships {
//           field_media_document {
//             filename
//             uri {
//               url
//               value
//             }
//           }
//         }
//       }
